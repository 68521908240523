<template>
  <svg
    width="13"
    height="16"
    viewBox="0 0 13 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.78525 11.69C0.642175 12.8331 0 14.3834 0 16H12.1905C12.1905 14.3834 11.5483 12.8331 10.4052 11.69C9.26215 10.5469 7.7118 9.90476 6.09524 9.90476C4.47868 9.90476 2.92833 10.5469 1.78525 11.69Z"
    />
    <path
      d="M1.52381 4.57143C1.52381 7.09714 3.56952 9.14286 6.09524 9.14286C8.62095 9.14286 10.6667 7.09714 10.6667 4.57143C10.6667 2.04571 8.62095 0 6.09524 0C3.56952 0 1.52381 2.04571 1.52381 4.57143Z"
    />
  </svg>
</template>
